import './style.scss';
import podmienky25Pdf from './DR-emisne-podmienky-Dajana-2025.pdf';
import podmienkyPdf from './2024_emisne podmienky_DR.pdf';
import podmienkySecondPdf from './2024_emisne_podmienky_DR_ISIN_SK4000025433.pdf';

const TextBlock = () => {
  return (
    <div className="text-block">
      <h2 className="text-block__heading">Informácie</h2>
      <p className="text-block__text">
        <span>
          Rozšírené informácie o našich dlhopisoch, vrátane úrokových sadzieb, termínov a investičných stratégií, nájdete v dokumente
          <br />
          <br />
          <a href={podmienkyPdf} rel="noreferrer" target="_blank" className="text-block__text--highlight">
            1. emisia dlhopisov Dajana Rodriguez.pdf
          </a>
          <br />
          <br />
          <a href={podmienkySecondPdf} rel="noreferrer" target="_blank" className="text-block__text--highlight">
            2. emisia dlhopisov Dajana Rodriguez.pdf
          </a>
          <br />
          <br />
          <a href={podmienky25Pdf} rel="noreferrer" target="_blank" className="text-block__text--highlight">
            3. emisia dlhopisov Dajana Rodriguez.pdf
          </a>
        </span>
      </p>
    </div>
  );
};

export default TextBlock;
