import React, { useState } from 'react';
import toast from 'react-hot-toast';
import './style.scss';

const InvestForm = () => {
  const phonePrefixes = ['+421', '+420', '+49'];

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    lastname: '',
    phone: '',
    phonePrefix: '+421',
    interestedInValue: 1000,
    street: '',
    city: '',
    birthNumber: '',
    nameTitle: '',
    investmentType: 'option1',
  });
  const [isSent, setIsSent] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'interestedInValue') {
      const intValue = parseInt(value.trim(), 10);
      setFormData({ ...formData, [name]: intValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    console.log(`name: ${name}, value: ${value}`);
  };

  const encodeForm = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  function isValidSlovakBirthNumber(birthNumber) {
    // Normalize: remove slashes, dashes, and spaces.
    let normalized = birthNumber.replaceAll('/', '').replaceAll('-', '').replaceAll(' ', '').trim();

    // The normalized string should contain only digits.
    if (!/^\d+$/.test(normalized)) {
      return false;
    }

    // Rodné číslo must be exactly 9 or 10 digits.
    if (normalized.length !== 9 && normalized.length !== 10) {
      return false;
    }

    // For 10-digit numbers (usually for persons born after January 1, 1954),
    // the number (when treated as an integer) must be divisible by 11.
    if (normalized.length === 10) {
      if (parseInt(normalized, 10) % 11 !== 0) {
        return false;
      }
    }

    // Extract date parts.
    // The format is: YYMMDD/SSSC (slash removed in our normalized string)
    let yearPart = normalized.substring(0, 2);
    let monthPart = normalized.substring(2, 4);
    let dayPart = normalized.substring(4, 6);

    let yearTwoDigit = parseInt(yearPart, 10);
    let rawMonth = parseInt(monthPart, 10);
    let day = parseInt(dayPart, 10);

    // Determine the full year.
    // For the older (9-digit) numbers, we assume 1900+year.
    // For 10-digit numbers, usually if year < 54 it is taken as 2000+year,
    // otherwise as 1900+year.
    let fullYear;
    if (normalized.length === 9) {
      fullYear = 1900 + yearTwoDigit;
    } else {
      fullYear = yearTwoDigit < 54 ? 2000 + yearTwoDigit : 1900 + yearTwoDigit;
    }
    let adjustedMonth;
    if ((rawMonth >= 1 && rawMonth <= 12) || (rawMonth >= 21 && rawMonth <= 32)) {
      // Male: if the raw month is above 12, an extra 20 was added.
      adjustedMonth = rawMonth > 12 ? rawMonth - 20 : rawMonth;
    } else if ((rawMonth >= 51 && rawMonth <= 62) || (rawMonth >= 71 && rawMonth <= 82)) {
      // Female: if the raw month is 71–82, subtract 70; otherwise subtract 50.
      adjustedMonth = rawMonth >= 71 && rawMonth <= 82 ? rawMonth - 70 : rawMonth - 50;
    } else {
      // The month code is not in any valid range.
      return false;
    }

    // Check that the adjusted month is within valid range (1 - 12).
    if (adjustedMonth < 1 || adjustedMonth > 12) {
      return false;
    }

    // Validate the date itself using JavaScript's Date object.
    // Note: In JavaScript, months are zero-indexed.
    let date = new Date(fullYear, adjustedMonth - 1, day);
    if (date.getFullYear() !== fullYear || date.getMonth() + 1 !== adjustedMonth || date.getDate() !== day) {
      return false;
    }

    // All tests passed; the birth number is valid.
    return true;
  }

  const validateForm = () => {
    const { email } = formData;
    if (!email) {
      toast.error('Prosím, vyplňte Váš email.');
      return false;
    }

    if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
      toast.error('Zadajte platný email.');
      return false;
    }

    // Check interestedInValue is number and multiple of 1000
    if (isNaN(formData.interestedInValue) || formData.interestedInValue % 1000 !== 0 || formData.interestedInValue > 20000) {
      toast.error('Zadajte platnú výšku investície (násobok 1000, max 20000).');
      return false;
    }

    // Check if birth number is with /
    if (!formData.birthNumber.includes('/')) {
      toast.error('Zadajte platné rodné číslo vo formáte 123456/1234.');
      return false;
    }

    // Slovak birth number validation
    // Format: 123456/1234 or 1234561234
    if (!isValidSlovakBirthNumber(formData.birthNumber)) {
      toast.error('Zadajte platné rodné číslo vo formáte 123456/1234.');
      return false;
    }

    return true;
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    fetch('https://curly-haze-5c0b.p-molcsan.workers.dev/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodeForm({ ...formData }),
    })
      .then(() => {
        //toast.success('Ďakujeme za Váš záujem, čoskoro Vás budeme kontaktovať.');
        setFormData({
          email: '',
          name: '',
          lastname: '',
          phone: '',
          phonePrefix: '+421',
          interestedInValue: 1000,
          street: '',
          city: '',
          birthNumber: '',
          nameTitle: '',
          investmentType: 'option1',
        });
        setIsSent(true);
      })
      //.catch(error => toast.error(error));
      .catch(() => toast.error('Niečo sa pokazilo, skúste to znova.'));
  };

  const handleBlur = e => {
    const { name, value } = e.target;
    if (name === 'interestedInValue') {
      const intValue = parseInt(value, 10);
      if (isNaN(intValue) || intValue % 1000 !== 0 || intValue > 20000) {
        toast.error('Zadajte platnú výšku investície (násobok 1000, max 20000).');
        setFormData({ ...formData, interestedInValue: 1000 });
      }
    }
  };

  const handleSelectChange = e => {
    setFormData({ ...formData, phonePrefix: e.target.value });
  };

  const formBeforeSent = isSent => {
    return (
      <div className={`opt-form__content ${isSent && 'opt-form__content--hidden'}`}>
        <h2>Záujem o investíciu</h2>
        <div className="opt-form__inputs">
          <div className="opt-form__input-field float-group">
            <input type="text" placeholder=" " name="nameTitle" value={formData.nameTitle} onChange={handleChange} />
            <label>
              Titul pred menom <br /> <small>Napr Ing.</small>
            </label>
          </div>
          <div className="opt-form__input-field float-group">
            <input autoComplete="on" type="text" placeholder=" " name="name" required value={formData.name} onChange={handleChange} />
            <label>
              Meno<span>*</span>
            </label>
          </div>
          <div className="opt-form__input-field float-group">
            <input autoComplete="on" type="text" placeholder=" " name="lastname" required value={formData.lastname} onChange={handleChange} />
            <label>
              Priezvisko<span>*</span>
            </label>
          </div>
          <div className="opt-form__input-field float-group">
            <input autoComplete="on" type="text" placeholder=" " name="birthNumber" required value={formData.birthNumber} onChange={handleChange} />
            <label>
              Rodné číslo<span>*</span>
            </label>
          </div>
          <div className="opt-form__input-field float-group">
            <input autoComplete="on" type="text" placeholder=" " name="street" required value={formData.street} onChange={handleChange} />
            <label>
              Ulica<span>*</span>
            </label>
          </div>
          <div className="opt-form__input-field float-group">
            <input autoComplete="on" type="text" placeholder=" " name="city" required value={formData.city} onChange={handleChange} />
            <label>
              Mesto<span>*</span>
            </label>
          </div>
          <div className="opt-form__input-field float-group">
            <input autoComplete="on" type="email" placeholder=" " name="email" required value={formData.email} onChange={handleChange} />
            <label>
              Email<span>*</span>
            </label>
          </div>
          <div className="opt-form__input-field float-group">
            <div className="phone-input">
              <select name="phonePrefix" value={formData.phonePrefix} onChange={handleSelectChange}>
                {phonePrefixes.map(prefix => (
                  <option key={prefix} value={prefix}>
                    {prefix}
                  </option>
                ))}
              </select>
              <input autoComplete="on" type="text" placeholder=" " name="phone" required value={formData.phone} onChange={handleChange} />
              <label>Telefón</label>
            </div>
          </div>
          <div className="opt-form__input-field float-group">
            <select name="interestedInValue" value={formData.interestedInValue} onChange={handleChange} onBlur={handleBlur}>
              {[...Array(20)].map((_, i) => (
                <option key={i} value={(i + 1) * 1000}>
                  {(i + 1) * 1000}
                </option>
              ))}
            </select>
            <label>
              Plánovaná výška investície (EUR) <span>*</span>
            </label>
          </div>
          <div className="opt-form__input-field">
            <label style={{ color: 'white', fontWeight: 600, fontFamily: 'Cinzel', fontSize: '16px' }}>Preferovaný spôsob prevzatia</label>
            <div className="checkbox">
              <input className="checkbox__input" type="radio" name="investmentType" value="option1" checked={formData.investmentType === 'option1'} onChange={handleChange} />
              <span className="checkbox__custom"></span>
              <span
                className="checkbox__label"
                onClick={() => {
                  setFormData({ ...formData, investmentType: 'option1' });
                }}
              >
                Poštou do vlastných rúk
              </span>
            </div>
            <div className="checkbox">
              <input className="checkbox__input" type="radio" name="investmentType" value="option2" checked={formData.investmentType === 'option2'} onChange={handleChange} />
              <span className="checkbox__custom"></span>
              <span
                className="checkbox__label"
                onClick={() => {
                  setFormData({ ...formData, investmentType: 'option2' });
                }}
              >
                Na centrále v Partizánskom
              </span>
            </div>
            <div className="checkbox">
              <input className="checkbox__input" type="radio" name="investmentType" value="option3" checked={formData.investmentType === 'option3'} onChange={handleChange} />
              <span className="checkbox__custom"></span>
              <span
                className="checkbox__label"
                onClick={() => {
                  setFormData({ ...formData, investmentType: 'option3' });
                }}
              >
                V predajni v Bratislave
              </span>
            </div>
          </div>
        </div>
        <button type="submit" className="button" style={{ backgroundColor: '#000', margin: '32px auto 0', fontSize: '18px' }}>
          <div className="button__circles">
            <span></span>
            <span></span>
          </div>
          Odoslať
        </button>
      </div>
    );
  };

  const formAfterSent = isSent => {
    return (
      <div className={`opt-form__thanks ${isSent && 'opt-form__thanks--active invest-form--thanks'}`}>
        <strong>
          Na váš email sme zaslali predvyplnený záujem o investíciu, ktorý môžete priamo elektronicky podpisovať a jeho potvrdením nám bude zaslaná kópia na email, následne stačí už len zrealizovať úhradu buď prevodom alebo cez zobrazený QR
          kód, ktorý je súčasťou zaslaných podkladov spolu aj s emisnými podmienkami.
        </strong>
      </div>
    );
  };

  return (
    <div className="opt-form-parent" style={{ display: 'flex', padding: '0 16px' }}>
      <section className="opt-form">
        <form name="emailing-blacklist" method="POST" onSubmit={handleSubmit}>
          <div className="circles">
            <span></span>
            <span></span>
          </div>
          {formBeforeSent(isSent)}
          {formAfterSent(isSent)}
        </form>
      </section>
    </div>
  );
};

export default InvestForm;
