import React, { useState } from 'react';
import toast from 'react-hot-toast';
import './style.scss';

const OptOutForm = () => {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [isSent, setIsSent] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const encodeForm = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const validateForm = () => {
    const { email } = formData;
    if (!email) {
      toast.error('Prosím, vyplňte Váš email.');
      return false;
    }

    if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
      toast.error('Zadajte platný email.');
      return false;
    }

    return true;
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    fetch('https://abra.p-molcsan.workers.dev/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodeForm({ ...formData }),
    })
      .then(() => {
        //toast.success('Ďakujeme za Váš záujem, čoskoro Vás budeme kontaktovať.');
        setFormData({
          email: '',
        });
        setIsSent(true);
      })
      //.catch(error => toast.error(error));
      .catch(() => toast.error('Niečo sa pokazilo, skúste to znova.'));
  };

  const formBeforeSent = isSent => {
    return (
      <div className={`opt-form__content ${isSent && 'opt-form__content--hidden'}`}>
        <h2>Odhlásenie z komunikácie</h2>
        <p> Ak si neželáte byť kontaktovaný ohľadom dlhopisov, odhláste sa tu.</p>
        <div className="opt-form__inputs">
          <div className="opt-form__input-field float-group">
            <input type="email" placeholder=" " name="email" required value={formData.email} onChange={handleChange} />
            <label>
              Email<span>*</span>
            </label>
          </div>
          <label className="checkbox">
            <input type="checkbox" className="checkbox__input" required={true} />
            <span className="checkbox__custom"></span>
            <span className="checkbox__label">Prajem sa odhlásiť z tohto typu emailingu a nechcem dostávať ďalšie informácie o dlhopisoch firmy DR s.r.o .</span>
          </label>
        </div>
        <button type="submit" className="button" style={{ backgroundColor: '#000', margin: '32px auto 0', fontSize: '18px' }}>
          <div className="button__circles">
            <span></span>
            <span></span>
          </div>
          Odoslať
        </button>
      </div>
    );
  };

  const formAfterSent = isSent => {
    return (
      <div className={`opt-form__thanks ${isSent && 'opt-form__thanks--active'}`}>
        <h2>Boli ste odhlásený</h2>
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', padding: '0 16px' }}>
      <section className="opt-form">
        <form name="emailing-blacklist" method="POST" onSubmit={handleSubmit}>
          <div className="circles">
            <span></span>
            <span></span>
          </div>
          {formBeforeSent(isSent)}
          {formAfterSent(isSent)}
        </form>
      </section>
    </div>
  );
};

export default OptOutForm;
