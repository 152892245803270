import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import foto1 from '../../img/foto-1.jpg';
import foto2 from '../../img/foto-2.jpg';

const ImageCarousel = () => {
  const images = [foto1, foto2];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState(0);
  const intervalRef = useRef(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const nextImage = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + images.length) % images.length);
  };

  const resetAutoplay = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(nextImage, 3000);
  };

  const handleTouchStart = e => {
    touchStartX.current = e.touches[0].clientX;
    setDragging(true);
    clearInterval(intervalRef.current);
  };

  const handleTouchMove = e => {
    touchEndX.current = e.touches[0].clientX;
    const dragDistance = touchEndX.current - touchStartX.current;
    const screenWidth = window.innerWidth;
    const dragPercentage = (dragDistance / screenWidth) * 100;
    setDragOffset(dragPercentage);
  };

  const handleTouchEnd = () => {
    setDragging(false);
    if (dragOffset > 10) {
      prevImage();
    } else if (dragOffset < -10) {
      nextImage();
    }
    setDragOffset(0);
    resetAutoplay();
  };

  const handleArrowClick = direction => {
    clearInterval(intervalRef.current);
    if (direction === 'left') {
      prevImage();
    } else {
      nextImage();
    }
    resetAutoplay();
  };

  useEffect(() => {
    intervalRef.current = setInterval(nextImage, 3000); // Change image every 3 seconds
    return () => clearInterval(intervalRef.current);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="image-carousel" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
      <button className="image-carousel__arrow image-carousel__arrow--left" onClick={() => handleArrowClick('left')}>
        &lt;
      </button>
      <div
        className="image-carousel__images"
        style={{
          transform: `translateX(${dragging ? dragOffset : -currentIndex * 100}%)`,
          transition: dragging ? 'none' : 'transform 0.5s ease-in-out',
        }}
      >
        {images.map((image, index) => (
          <img key={index} src={image} alt={`Carousel-${index}`} className={`image-carousel__image ${index === currentIndex ? 'active' : ''}`} />
        ))}
      </div>
      <button className="image-carousel__arrow image-carousel__arrow--right" onClick={() => handleArrowClick('right')}>
        &gt;
      </button>
    </div>
  );
};

export default ImageCarousel;
